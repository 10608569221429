import './region-slider.scss';
import Swiper, { EffectFade, FreeMode, Navigation, Pagination, Thumbs } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/free-mode';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/thumbs';

class RegionSlider {
    constructor ($element) {
        this.$regionSlider = $element;
        this.$logoSlider = this.$regionSlider.querySelector('[data-region-slider="logos"]');
        this.$slider = this.$regionSlider.querySelector('[data-region-slider="slides"]');
        this.$sliderPagination = this.$regionSlider.querySelector('[data-region-slider="pagination"]');
        this.$slidePrev = this.$regionSlider.querySelector('[data-region-slider="navigation-left"]');
        this.$slideNext = this.$regionSlider.querySelector('[data-region-slider="navigation-right"]');
        this.$slidePrevMobile = this.$regionSlider.querySelector('[data-region-slider="navigation-mobile-left"]');
        this.$slideNextMobile = this.$regionSlider.querySelector('[data-region-slider="navigation-mobile-right"]');
        this.itemCount = null;

        this.sliderConfig = {};
        this.logoSlider = undefined;
        this.slider = undefined;

        this.usedSlideMethod = null;
        this.breaker = null;

        if (this.$logoSlider) {
            this.itemCount = parseInt(this.$logoSlider.getAttribute('data-count'));
        }
    }

    initialize () {
        if (this.$logoSlider) {
            this.initLogoSlider();
            this.initSlider();
        } else {
            this.initSlider();
        }

        this.setEvents();
    }

    setEvents () {
        if (this.$logoSlider && this.$slidePrevMobile && this.$slideNextMobile) {
            this.$slidePrevMobile.addEventListener('click', () => {
                this.slider.slidePrev();
            });

            this.$slideNextMobile.addEventListener('click', () => {
                this.slider.slideNext();
            });
        }

        if (this.$sliderPagination) {
            this.$sliderPagination.addEventListener('click', (e) => {
                const index = e.target.getAttribute('data-pagination-index');

                if (index !== null) {
                    this.slider.slideTo(index - 1);
                    if (!e.target.classList.contains('is--active')) {
                        this.$sliderPagination.querySelector('.is--active').classList.remove('is--active');
                        e.target.classList.add('is--active');
                    }
                }
            });

            this.slider.on('transitionStart', () => {
                const index = this.slider.activeIndex + 1;
                this.$sliderPagination.querySelector('.is--active').classList.remove('is--active');

                const $targetIndex = this.$sliderPagination.querySelector('[data-pagination-index="' + index + '"]');

                if ($targetIndex) {
                    $targetIndex.classList.add('is--active');
                }
            });
        }
    }

    initLogoSlider () {
        this.logoSlider = new Swiper(this.$logoSlider, {
            modules: [FreeMode, Navigation],
            slidesPerView: 4,
            spaceBetween: 75,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                prevEl: this.$slidePrevMobile,
                nextEl: this.$slideNextMobile
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 50
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 50
                },
                1280: {
                    slidesPerView: 5,
                    spaceBetween: 50
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 50
                }
            }
        });
    }

    initSlider () {
        this.setSliderConfig();

        this.slider = new Swiper(this.$slider, this.sliderConfig);

        setTimeout(() => {
            this.slider.update();
        }, 500);
    }

    setSliderConfig () {
        if (this.$logoSlider) {
            this.sliderConfig = {
                modules: [EffectFade, Navigation, Thumbs],
                slidesPerView: 1,
                autoHeight: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                speed: 500,
                navigation: {
                    prevEl: this.$slidePrev,
                    nextEl: this.$slideNext
                },
                thumbs: {
                    swiper: this.logoSlider
                }
            };
        } else {
            this.sliderConfig = {
                modules: [EffectFade, Navigation, Pagination],
                slidesPerView: 1,
                autoHeight: true,
                effect: 'fade',
                speed: 500,
                navigation: {
                    prevEl: this.$slidePrev,
                    nextEl: this.$slideNext
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }
            };
        }
    }
}

export { RegionSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $regionSlider = $context.querySelectorAll('[data-region-slider="root"]');

        for (let i = 0; i < $regionSlider.length; i++) {
            const $$regionSlider = new RegionSlider($regionSlider[i]);
            $$regionSlider.initialize();
        }
    }
});
